












































import { Component, Vue } from 'vue-property-decorator'
import { BContainer, BRow, BCol, BImg, BButton, VBToggle, BCard, BCardText, BFormInput, BCardBody, BModal, VBModal } from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import vSelect from 'vue-select'

@Component({
  name: 'ModalDownload',
  components: {
    BContainer, BRow, BCol, BImg, BButton, AppCollapse, AppCollapseItem, BCard, BCardText, BCardBody, BFormInput, BModal, vSelect
  },
  directives: {
    'b-toggle': VBToggle,
    'b-modal': VBModal
  }
})
export default class ModalDownload extends Vue {

}
