








































































































































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import { BContainer, BRow, BCol, BImg, BButton, VBToggle, BCard, BCardText, BFormInput, BCardBody, BModal, VBModal } from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import vSelect from 'vue-select'
import { Getter } from '@/store/decorators'
import AuthModule from '@/store/modules/authModule'
import { User } from '@/models/User'
import ModalDownload from '@/views/pages/components/modalDownload.vue'

@Component({
  name: 'Homepage',
  components: {
    BContainer, BRow, BCol, BImg, BButton, AppCollapse, AppCollapseItem, BCard, BCardText, BCardBody, BFormInput, BModal, vSelect, ModalDownload
  },
  directives: {
    'b-toggle': VBToggle,
    'b-modal': VBModal
  }
})
export default class Homepage extends Vue {
  @Getter(AuthModule, 'activeUser') private activeUser!: User

  private faqFirst : any[] = [
    {
      title: 'What is Washir?',
      content: 'Washir is a technology company which allows users to order a wash from anywhere to our network of existing mobile washir -pros. ',
      additional: ''
    },
    {
      title: 'How can I become a Washir-Pro?',
      content: 'If you are a professional do a car wash and detailing, and you have all the necessary licenses and equipment, you can register on Washir as a Washir-Pro. After your submission approved, we will send the carwash request to your app and based on your location and services you provide; you can accept the orders. ',
      additional: ''
    },
    {
      title: 'Do you wash bikes, trucks, boats or planes? ',
      content: ' We are offering a wide range of variety of services for all the vehicles such as cars, trucks and big trucks. Cleaning bikes, boats, and planes will are added very soon. ',
      additional: ''
    },
    {
      title: 'What to do when your washer cancels your order? ',
      content: 'When a Washir-Pro cancels the order, we will send your request to all the other available "Washir-Pros" in your area. If there were no Washir-Pro available, your order would be canceled, and you need to order again. ',
      additional: ''
    },
    {
      title: 'How can you add multiple cars to order? ',
      content: ' After selecting your location, you can select one of the saved cars or add a new car. After finishing your first order, before payment method, you have an option to add another car. You only can add up to 5 cars to a single order. ',
      additional: ''
    },
    {
      title: 'What to do when I place an order? ',
      content: 'After a minute of requesting your wash, the app will send your request to all our local "Washir-Pros." Based on your car(s), package, options and your availabilities, one of our "Washir-Pro" will accept your request. After "Washir-Pro" accepts your request, we will send you a notification, and you can see the profile, specialties, history, comments, and rating of the "Washir-pro." If you are not happy with that choice, you can cancel your order. ',
      additional: ''
    }
  ];
  private faqSecond: any[] = [
    {
      title: 'Who is Washir-Pro?',
      content: 'Washir is a platform provider and does not serve as a cleaning service or a laundry company. Our professional washer and detailers run all services. They are ready to do different types of services based on location. Each Washir-Pro has his/her specialty, and Washir provides a right Washir-Pro based on customer order. <br> You can see all about the Washir-Pro before you get your service. If he/she does not match your criteria, you can cancel the order.',
      additional: ''
    },
    {
      title: 'How do I get paid as a Washir-Pro? ',
      content: ' After finishing the request and customer confirmation that they received the service, we will charge the customer. We will process the payment to "Washir-Pro" as soon as we receive the charge. ',
      additional: ''
    },
    {
      title: 'How and when I can cancel my order?',
      content: 'If you order a "wash-now" or a washir-pro accept your order less than one hour of the appointment time, you have 15 min to cancel your order after Washir-Pro accepts your order. If you have an appointment for more than one hour and a "Washir-Pro" accepts your request, you only can cancel your order until one hour before your appointment.',
      additional: 'There will be a $10 cancelation fee if you cancel your order'
    },
    {
      title: 'What are the requirements to order services?',
      content: 'The Washir-Pro will come fully prepared to complete your order. Make sure you are present at the time of washing (otherwise cancelation fee will apply). Also, make sure you have required permissions to do wash and detailing in your location (otherwise cancelation fee will apply). ',
      additional: ''
    },
    {
      title: 'How can you add multiple cars to order?',
      content: ' After selecting your location, you can select one of the saved cars or add a new car. After finishing your first order, before payment method, you have an option to add another car. You only can add up to 5 cars to a single order. ',
      additional: ''
    }
  ];
  private form : any = {
    country: 'United State',
    firstName: '',
    lastName: '',
    zipCode: '',
    emailAddress: ''
  }
}
